import React from 'react';
import { render } from 'react-dom';

import './stylesheets/be_right_back';

import App from './components/common/App';

import './helpers/fonticons';

import store from './redux/store';
import { Provider } from 'react-redux';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from './query/queryClient';

document.addEventListener('DOMContentLoaded', () => {
  render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>,
    document.body.appendChild(document.createElement('div')),
  )
})

window.addEventListener('load', () => {

  // eslint-disable-next-line
  if (process.env.NODE_ENV === 'development') return;

  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('ServiceWorker registered: ', registration);
    }).catch(registrationError => {
      console.log('Service worker registration failed: ', registrationError);
    });
  }
});

if (window.Cypress) {
  window.__queryClient__ = queryClient;
}
